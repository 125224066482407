import {
  SMARTOFFICE_GET_CONTACTS,
  SMARTOFFICE_GET_CONTACTS_SUCCESS,
  SMARTOFFICE_GET_CONTACTS_ERROR,
  SMARTOFFICE_GET_CONVERSATIONS,
  SMARTOFFICE_GET_CONVERSATIONS_SUCCESS,
  SMARTOFFICE_GET_CONVERSATIONS_ERROR,
} from '../actions';


const INIT_STATE = {
  conversations: [],
  contacts: [],
  searchKeyword: '',
  loadingConversations: true,
  loadingContacts: true,
  loadingConversationsError: false,
  loadingContactsError: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SMARTOFFICE_GET_CONTACTS:
      return {...state };

    case SMARTOFFICE_GET_CONTACTS_SUCCESS:
      return {...state,
        contacts: action.payload.contacts,
        loadingContacts: false
      };

    case SMARTOFFICE_GET_CONTACTS_ERROR:
      return {
        ...state,
        loadingContactsError: action.payload.error,
        loadingContacts: false
      };

    case SMARTOFFICE_GET_CONVERSATIONS:
      return {
        ...state,
        loadingConversations: true
      };

    case SMARTOFFICE_GET_CONVERSATIONS_SUCCESS:

      return {
        ...state,
        conversations: {
          ...action.payload.conversation,
          result: action.payload.conversation.result
        },
        loadingConversations: false
      };

    case SMARTOFFICE_GET_CONVERSATIONS_ERROR:
      return {
        ...state,
        loadingConversationsError: action.payload.error,
        loadingConversations: false
      };

    default:
      return state;
  }
}
