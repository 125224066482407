import {
  SMARTOFFICE_GET_CONTACTS,
  SMARTOFFICE_GET_CONTACTS_SUCCESS,
  SMARTOFFICE_GET_CONTACTS_ERROR,
  SMARTOFFICE_GET_CONVERSATIONS,
  SMARTOFFICE_GET_CONVERSATIONS_SUCCESS,
  SMARTOFFICE_GET_CONVERSATIONS_ERROR,
} from '../actions';


export const getSmartofficeContacts = () => ({
  type: SMARTOFFICE_GET_CONTACTS
});

export const getSmartofficeContactsSuccess = (contacts) => {
  return ({
    type: SMARTOFFICE_GET_CONTACTS_SUCCESS,
    payload: { contacts }
  })
};

export const getSmartofficeContactsError = (error) => ({
  type: SMARTOFFICE_GET_CONTACTS_ERROR,
  payload: error
});

export const getSmartofficeConversations = (filter) => ({
  type: SMARTOFFICE_GET_CONVERSATIONS,
  payload: { filter }
});
export const getSmartofficeConversationsSuccess = (conversation) => ({
  type: SMARTOFFICE_GET_CONVERSATIONS_SUCCESS,
  payload: { conversation }
});

export const getSmartofficeConversationsError = (error) => ({
  type: SMARTOFFICE_GET_CONVERSATIONS_ERROR,
  payload: error
});


